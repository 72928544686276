import React, { Fragment } from 'react'
import Props from 'prop-types'
import { Link } from 'gatsby'

import ScrollLink from 'components/ScrollLink'
import { Li } from './styled'

const MenuItems = ({ isFooterNav, onScrollClick, translates }) => {
  const {
    about,
    qa,
    contact,
    privacyPolicy,
    termsOfUse,
    reports,
  } = translates.menu

  return (
    <Fragment>
      <Li>
        <ScrollLink onScrollClick={onScrollClick} to="#about">
          {about}
        </ScrollLink>
      </Li>
      <Li>
        <Link
          to={`/${translates.slug !== 'en' ? `${translates.slug}/` : ''}qa?web`}
          onClick={onScrollClick}
        >
          {qa}
        </Link>
      </Li>
      <Li>
        <Link to="/community-guidelines?web" onClick={onScrollClick}>
          Guidelines
        </Link>
      </Li>
      <Li>
        <ScrollLink onScrollClick={onScrollClick} to="#contact">
          {contact}
        </ScrollLink>
      </Li>
      <Li mobileOnly={!isFooterNav}>
        <Link to="/privacy-policy?web" onClick={onScrollClick}>
          {privacyPolicy}
        </Link>
      </Li>
      <Li mobileOnly={!isFooterNav}>
        <Link to="/terms-of-use?web" onClick={onScrollClick}>
          {termsOfUse}
        </Link>
      </Li>
      <Li mobileOnly={!isFooterNav}>
        <a
          href="https://drive.google.com/drive/folders/1F3xyXsilhNtf9dEGtHra-8XTq0qSJdL1"
          target="_blank"
          rel="noopener noreferrer"
        >
          {reports}
        </a>
      </Li>
    </Fragment>
  )
}

MenuItems.propTypes = {
  isFooterNav: Props.bool,
  onScrollClick: Props.func,
  translates: Props.any,
}

MenuItems.defaultProps = {
  isFooterNav: false,
  onScrollClick: () => null,
  translates: {},
}

export default MenuItems
